import React from 'react';
import ReactDOM from 'react-dom';
import './app.css';
import reportWebVitals from './reportWebVitals';

const url = "https://cloudflare-hiring-general.ctorek.workers.dev";

function renderPost(post) {
  return (
    <div className="post" id={post.title}> 
      <h3 className="postTitle">{post.title}</h3> 
      <p className="postAuthor">{post.username}</p> 
      <p className="postContent">{post.content}</p> 
    </div>
  );
}

class Posts extends React.Component {
  state = {
    postList: []
  };

  // Runs when component is inserted into the tree
  componentDidMount() {
    // Fetch posts from backend
    fetch(`${url}/posts`)
    .then(response => response.json()) // .json() returns another promise
    .then(body => {
      // Update state after response
      this.setState({ postList: body });
    }).catch(error => {
      console.error(error);
    });
  }

  render() {
    // Check for response from backend
    if (this.state.postList === null) {
      // Loading state
      return <h2> No posts! </h2>
    } else if (!Array.isArray(this.state.postList)) {
      // If only one post is present, just one object is returned
      return renderPost(this.state.postList);
    } else {
      // State when posts are received
      let renderedPosts = [];
      for (const post of this.state.postList) {
        // Render each post
        renderedPosts.push(renderPost(JSON.parse(post)));
      }

      return <div id="allPosts"> {renderedPosts} </div>;
    }
  }
}

class PostForm extends React.Component {
  state = {
    title: "",
    username: "",
    content: "Post content..."
  };

  constructor(props) {
    super(props);

    // Bind is required for the method to have access to `this`
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      // ES6 computed property name
      [event.target.name]: event.target.value
    })
  }

  handleSubmit(event) {
    // Prevent page reload
    event.preventDefault(); 

    fetch(`${url}/posts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state)
    })
    .then(response => {
      // Request will fail if title is the same as a previous post
      if (!response.ok) {
        alert("Post must not have the same title as another post.");
        return;
      }
      
      return response.text();
    })
    .then(body => {
      console.log(body)
    }).catch(error => {
      console.error(error);
      alert("Post failed due to internal error: " + error);
    });

    // Clear form after post
    this.setState(state => {
      for (const property in state) {
        state[property] = "";
      }

      return state;
    });

    alert("Post has been submitted!");
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label for="title">
          Title:
        </label> 
        <input id="title" type="text" name="title" value={this.state.title} onChange={this.handleChange} />
        <label for="user">
          Username:
        </label>
        <input id="user" type="text" name="username" value={this.state.username} onChange={this.handleChange} />
        <textarea name="content" value={this.state.content} onChange={this.handleChange} /> <br />
        <button type="submit">Submit</button>
      </form>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <PostForm /> <br />
    <Posts />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();